<template>
  <div class="home bg-gray-100 font-inter min-h-screen">
    <div class="relative  pt-16 pb-8 sm:pt-16 sm:pb-8 lg:pt-16 lg:pb-8">
        <div class="relative">
          <div class="text-center mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
              <h1>
              SWAP USDC
              </h1>
          </div>
        </div>
      </div>
      <div class="relative bg-white content-txt">
         <div class="mx-auto max-w-md px-4 py-8 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl space-y-6">
            <div class="flex flex-col w-1/4 m-auto items-center space-y-6 border-b-2 pb-4">
                <div class="position">
                  <svg width="49" height="48" viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M48.5 24C48.5 37.2548 37.7548 48 24.5 48C11.2452 48 0.5 37.2548 0.5 24C0.5 10.7452 11.2452 0 24.5 0C37.7548 0 48.5 10.7452 48.5 24Z" fill="black"/>
                    <path d="M20.2781 28.24H22.9981V21.68H20.5981V19.56C21.3048 19.4267 21.9115 19.2667 22.4181 19.08C22.9248 18.88 23.4248 18.6267 23.9181 18.32H26.4381V28.24H28.7181V31H20.2781V28.24Z" fill="white"/>
                  </svg>
                </div>
                <p class="text-center text-gray-700">Open Metamask and click on swap</p>
                <img src="../../assets/images/metamask_one.png" class="border-2">
            </div>
            <div class="flex flex-col w-1/4 m-auto items-center space-y-6  border-b-2 pb-4">
                <div class="position">
                  <svg width="49" height="49" viewBox="0 0 49 49" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M48.5 24.8667C48.5 38.1215 37.7548 48.8667 24.5 48.8667C11.2452 48.8667 0.5 38.1215 0.5 24.8667C0.5 11.6119 11.2452 0.866699 24.5 0.866699C37.7548 0.866699 48.5 11.6119 48.5 24.8667Z" fill="black"/>
                  <path d="M19.8196 29.9067C21.1129 28.72 22.0996 27.7867 22.7796 27.1067C23.4729 26.4134 24.0396 25.7467 24.4796 25.1067C24.9196 24.4534 25.1396 23.8534 25.1396 23.3067C25.1396 22.76 24.9996 22.3467 24.7196 22.0667C24.4396 21.7734 24.0596 21.6267 23.5796 21.6267C23.2063 21.6267 22.8596 21.7334 22.5396 21.9467C22.2329 22.1467 21.8863 22.44 21.4996 22.8267L19.6596 20.9867C20.3396 20.2667 21.0129 19.7467 21.6796 19.4267C22.3463 19.1067 23.1396 18.9467 24.0596 18.9467C24.9129 18.9467 25.6663 19.12 26.3196 19.4667C26.9863 19.8134 27.4996 20.3 27.8596 20.9267C28.2329 21.5534 28.4196 22.28 28.4196 23.1067C28.4196 24.0267 28.0796 24.9867 27.3996 25.9867C26.7329 26.9867 25.8596 28.04 24.7796 29.1467C25.6329 29.04 26.3396 28.9867 26.8996 28.9867H29.0196V31.8667H19.8196V29.9067Z" fill="white"/>
                  </svg>

                </div>
                <p class="text-center text-gray-700">Select the amount you need to convert in the Swap from field.<br />
                    Select USDC in the Swap to field</p>
                <img src="../../assets/images/metamask_two.png" class="border-2">
            </div>
            <div class="flex flex-col w-1/4 m-auto items-center space-y-6">
                <div class="position">
                  <svg width="48" height="49" viewBox="0 0 48 49" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M48 24.7332C48 37.988 37.2548 48.7332 24 48.7332C10.7452 48.7332 0 37.988 0 24.7332C0 11.4783 10.7452 0.733154 24 0.733154C37.2548 0.733154 48 11.4783 48 24.7332Z" fill="black"/>
                  <path d="M23.5196 31.9732C22.5196 31.9732 21.6396 31.8198 20.8796 31.5132C20.1196 31.1932 19.4929 30.7465 18.9996 30.1732L20.5596 28.0132C20.9596 28.3865 21.3796 28.6798 21.8196 28.8932C22.2729 29.1065 22.7196 29.2132 23.1596 29.2132C23.7329 29.2132 24.1796 29.1132 24.4996 28.9132C24.8329 28.6998 24.9996 28.3998 24.9996 28.0132C24.9996 27.6665 24.9129 27.3865 24.7396 27.1732C24.5796 26.9465 24.2663 26.7798 23.7996 26.6732C23.3463 26.5532 22.6929 26.4932 21.8396 26.4932V24.0932C22.8529 24.0932 23.5663 23.9732 23.9796 23.7332C24.3929 23.4798 24.5996 23.1198 24.5996 22.6532C24.5996 22.2798 24.4863 21.9932 24.2596 21.7932C24.0329 21.5932 23.7063 21.4932 23.2796 21.4932C22.8796 21.4932 22.5129 21.5798 22.1796 21.7532C21.8463 21.9132 21.4663 22.1732 21.0396 22.5332L19.3196 20.4532C20.6263 19.3598 21.9996 18.8132 23.4396 18.8132C24.8529 18.8132 25.9729 19.1265 26.7996 19.7532C27.6263 20.3798 28.0396 21.2598 28.0396 22.3932C28.0396 23.6198 27.3596 24.5332 25.9996 25.1332V25.2132C26.7596 25.4398 27.3529 25.8132 27.7796 26.3332C28.2196 26.8398 28.4396 27.4798 28.4396 28.2532C28.4396 29.0265 28.2129 29.6932 27.7596 30.2532C27.3196 30.8132 26.7263 31.2398 25.9796 31.5332C25.2329 31.8265 24.4129 31.9732 23.5196 31.9732Z" fill="white"/>
                  </svg>
                </div>
                <p class="text-center text-gray-700">Swap your cryptocurrency</p>
                <img src="../../assets/images/metamask_three.png" class="border-2">
            </div>
             
          </div>
      </div>
  </div>
</template>
 